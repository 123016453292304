import { Callbacks } from '../callbacks';
import { IPlayer } from '../iplayer';
import { PlayerFactory } from '../player-factory';

import { logger, SourceSet } from 'vchat-core';

export interface TestPlayerConfig {
    name: string;
    format: string | string[];
    supported: boolean;

    raiseErrorAfter?: number;
}

export class DummyPlayerFactory implements PlayerFactory {
    get format(): string | string[] {
        return this.config.format;
    }

    private logName: string;

    private config: TestPlayerConfig;

    constructor(config: TestPlayerConfig) {
        this.config = config;
        this.logName = 'TestPlayerFactory[' + config.name + ']';
    }

    public isSupported(): boolean {
        return this.config.supported;
    }

    public async create(
        callbacks: Callbacks,
        video: HTMLVideoElement
    ): Promise<IPlayer> {
        this.log('.create', video);

        const cont: HTMLDivElement = document.createElement('div');
        cont.style.width = '100%';
        cont.style.height = '100%';

        let sources = [];

        const player = {
            el: [cont],
            name: 'dummy' + this.config.name,
            play: (sourceSet: SourceSet, format: string): void => {
                this.log('.play', sourceSet, format);
                sources = sourceSet[format];
                if (sources && sources.length > 0) {
                    callbacks.onPlayStart(sources[0]);
                }

                if (this.config.raiseErrorAfter > 0) {
                    setTimeout(() => {
                        callbacks.onError('breakAfter');
                    }, this.config.raiseErrorAfter);
                }
            },

            stop: (): void => {
                this.log('.stop');
                callbacks.onPlayStop();
            },

            destroy: (): HTMLVideoElement => {
                this.log('.destroy');
                player.stop();

                return null;
            },

            setVolume: (value): void => {
                this.log('.setVolume', value);
            }
        };
        return player;
    }

    private log(message: string, ...optionalParams: any[]): void {
        const p = [
            this.logName + (message.charAt(0) === '.' ? '' : ' ') + message
        ].concat(optionalParams);
        logger.log.apply(undefined, p);
    }
}
