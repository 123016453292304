import { Callbacks } from '../../callbacks';
import { CameraConfig } from '../../camera-config';
import { IPlayer } from '../../iplayer';

import { logger, RtmpSource, SourceSet, TargetSet } from 'vchat-core';
import { CameraPosition } from '../../camera-position';

let flashInfoData = null;

export const flashInfo = (): any => {
    if (!flashInfoData) {
        const isDef = (value: PluginArray): boolean => {
            return typeof value !== 'undefined';
        };
        const ua = navigator.userAgent.toLowerCase();
        let oldIEMode = navigator.appName === 'Microsoft Internet Explorer';
        let pluginMode = false;
        let major = 0;
        let minor = 0;
        const r = /edge\/(\d+)/.exec(navigator.appName);
        const edge2017 = r && parseInt(r[1], 10) >= 15; // 2017-04 flash support is broken

        if (!edge2017 && isDef(navigator.plugins)) {
            const flash = navigator.plugins['Shockwave Flash'];
            if (typeof flash === 'object') {
                pluginMode = true;
                oldIEMode = false;
                const v = /(\d+)\.(\d+)/.exec(flash.description);
                if (v) {
                    major = parseInt(v[1], 10);
                    minor = parseInt(v[2], 10);
                }
            } else {
                const win = ua.includes('windows');
                const mac = ua.includes('mac');

                if (!!window['chrome'] && (win || mac)) {
                    const v = /chrome\/(\d+)/.exec(ua);
                    const chromeVersion = v ? parseInt(v[1], 10) : 0;

                    if (
                        chromeVersion >= 56 &&
                        !/(?:opr|vivaldi|yabrowser|dragon)\//.test(ua)
                    ) {
                        // google chrome for win/mac and not other chromium based browser
                        pluginMode = true;
                        oldIEMode = false;
                        major = 999;
                        minor = 0;
                    }
                }
            }
        }

        if (!pluginMode) {
            try {
                /* eslint-disable no-undef */
                const ax = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
                /* eslint-enable no-undef */

                if (ax) {
                    const v = /(\d+),(\d+)/.exec(ax.GetVariable('$version'));
                    if (v) {
                        oldIEMode = true;
                        major = parseInt(v[1], 10);
                        minor = parseInt(v[2], 10);
                    }
                }
            } catch (error) {
                /* ignore as this is only valid on IE */
            }
        }
        flashInfoData = { major, minor, oldIEMode };
    }
    return flashInfoData;
};

function param(name: string, value: string): HTMLParamElement {
    const p = document.createElement('param') as HTMLParamElement;
    p.name = name;
    p.value = value;
    return p;
}

function buildFlashObject(
    id: string,
    src: string,
    flashvars: string,
    wmode = 'window'
): SWFPlayer {
    // @see https://helpx.adobe.com/flash/kb/flash-object-embed-tag-attributes.html
    const params = {
        id,

        allowfullscreen: false,
        allowscriptaccess: 'always',
        bgcolor: '#000',
        browserzoom: 'noscale',
        flashvars,
        quality: 'high',
        wmode
    };

    const obj: HTMLObjectElement = document.createElement(
        'object'
    ) as HTMLObjectElement;
    obj.width = '100%';
    obj.height = '100%';
    obj.id = id;
    obj.name = id;

    if (flashInfo().oldIEMode) {
        (obj as any).classid = 'clsid:D27CDB6E-AE6D-11cf-96B8-444553540000';
        obj.append(param('movie', src));
    } else {
        obj.data = src;
        obj.type = 'application/x-shockwave-flash';
    }

    for (const key in params) {
        obj.append(param(key, params[key]));
    }

    return obj as SWFPlayer;
}

function findUniqueName(): string {
    let name = '';
    let max = 99;
    do {
        if (max-- === 0) {
            return null;
        }
        name =
            '_rtmpPlayer_' + Math.ceil(Math.random() * 0x1000000).toString(16);
    } while (
        Object.prototype.hasOwnProperty.call(window, name) &&
        document.querySelector('#' + name)
    );
    return name;
}

export let swfFails: number = (function(): number {
    const r = /player_swf_fails\s*=\s*(\d+)-(\d+).*/.exec(document.cookie);
    if (r) {
        return parseInt(r[1], 10) || 0;
    }
    return 0;
})();

function updateFails(value): void {
    swfFails = value;

    let cookie = 'player_swf_fails=' + swfFails;

    if (swfFails) {
        cookie += '-' + Math.floor(Date.now() / 1000) + ';max-age=' + 86400 * 7;
    }

    cookie += ';path=/';

    document.cookie = cookie;
}

export const dummy = (): void => {
    /** noop */
};

interface SWFPlayer extends HTMLObjectElement {
    play(app: string, stream: string): void;
    stop(): void;
    hasCamera(): boolean;
    startCamera(config: any);
    stopCamera();
    publish(app: string, stream: string): void;
    unpublish(): void;
    setVolume(value: number): void;
    getVolume(): number;
}

const enum PlayerState {
    UNDEF,
    STOP,
    PLAY
}

/**
 * A player for flash streams.
 * supported format:  rtmp
 * name:              RTMP
 */
export class RtmpPlayer implements IPlayer {
    public readonly name = 'RTMP';
    public canPublish = true;
    public el: HTMLElement[];

    private id: string;
    private state: PlayerState = PlayerState.UNDEF;
    private cameraState = 0;
    private publishState = 0;
    private queue: any[] = [];
    private sources: RtmpSource[] = [];
    private currentSource: string;
    private cb: Callbacks;
    private swf: SWFPlayer;
    private initTimeout: number;
    private videoHeight: number;
    private videoWidth: number;

    constructor(
        callbacks: Callbacks,
        swfPath: string,
        timeout: number,
        onInit: () => void,
        canPublish: boolean
    ) {
        const id = findUniqueName();
        if (!id) {
            throw new Error("can't get id");
        }

        this.cb = callbacks;
        this.canPublish = canPublish;

        this.initTimeout = window.setTimeout(() => {
            if (this.state === PlayerState.UNDEF) {
                this.queue.forEach((entry) => {
                    if (entry.onFail) {
                        entry.onFail();
                    }
                });
                this.queue = [];
                updateFails(swfFails + 1);
                callbacks.onError('timeout');
            }
        }, timeout);

        this.id = id;
        window[id] = {
            onLog: (...args): void => {
                args.unshift('RtmpPlayer:handler.onLog');
                logger.log.apply(this, args);
            },

            onPreInit: (): void => {
                // logger.log('RtmpSource.handler.onPreInit')
            },

            onInit: (version: string | number): void => {
                logger.log('RtmpPlayer:handler.onInit ' + version);
                updateFails(0);
                if (version >= 1) {
                    this.state = PlayerState.STOP;
                    clearTimeout(this.initTimeout);
                    setTimeout(() => {
                        if (this.state) {
                            this.queue.forEach((entry) => entry.cmd());
                            this.queue = [];
                        }
                    }, 0);

                    onInit();
                } else {
                    // incompatible version
                    callbacks.onError('incompatible');
                }
            },

            onConnect: (): void => {
                logger.log('RtmpPlayer:handler.onConnect');
            },

            onPlay: (stream: string): void => {
                logger.log('RtmpPlayer:handler.onPlay', stream);
                this.state = PlayerState.PLAY;
                callbacks.onPlayStart(stream);
            },

            onStop: (stream): void => {
                logger.log('RtmpPlayer:handler.onStop', stream);
                this.state = PlayerState.STOP;
                callbacks.onPlayStop();
            },

            onPublish: (): void => {
                this.publishState = 2;
                callbacks.onPublishStart();
            },

            onUnpublish: (): void => {
                this.publishState = 0;
                callbacks.onPublishStop();
            },

            onVideoSize: (width: number, height: number): void => {
                logger.log('RtmpPlayer:handler.onVideoSize', width, height);
                this.videoHeight = height;
                this.videoWidth = width;

                this.sendPlayInfo();
            },

            onCameraStatus: (value: number): void => {
                logger.log('RtmpPlayer:handler.onCameraStatus', value);
                this.cameraState = value;
                if (value === 1) {
                    callbacks.onCameraOn();
                } else if (value === 0) {
                    callbacks.onCameraOff();
                } else {
                    callbacks.onCameraDenied();
                }
            }
        };

        const flashvars = `handler=${id}`;
        this.swf = buildFlashObject(id, swfPath, flashvars);
        this.el = [this.swf];
    }

    public play(sourceSet: SourceSet): void {
        this.sources = sourceSet.rtmp ? sourceSet.rtmp.slice() : [];
        this.playNext();
    }

    public stop(): void {
        this.eorq(() => this.swf.stop());
    }

    public hasCamera(): Promise<boolean | undefined> {
        return new Promise((resolve) => {
            this.eorq(
                () => {
                    resolve(this.swf.hasCamera());
                },
                () => {
                    resolve(undefined);
                }
            );
        });
    }

    public startCamera(params: CameraConfig): Promise<any> {
        if (typeof params !== 'object') {
            params = { position: params };
        }

        if (!params.position) {
            params.position = CameraPosition.TopLeft;
        }

        return new Promise((_resolve, reject) => {
            this.eorq(
                () => {
                    this.swf.startCamera(params);
                },
                () => {
                    reject();
                }
            );
        });
    }

    public stopCamera(): void {
        this.eorq(() => this.swf.stopCamera());
    }

    public publish(targetSet: TargetSet): boolean {
        if (targetSet.rtmp && targetSet.rtmp.length > 0) {
            const app = targetSet.rtmp[0].app;
            const stream = targetSet.rtmp[0].stream;

            this.publishState = 1;

            this.eorq(() => this.swf.publish(app, stream));
            return true;
        }
        return false;
    }

    public unpublish(): void {
        this.publishState = -1;
        this.eorq(() => this.swf.unpublish());
    }

    public destroy(): HTMLVideoElement {
        clearTimeout(this.initTimeout);

        if (this.state === PlayerState.PLAY) {
            this.stop();
        }

        if (this.publishState !== 0) {
            this.cb.onPublishStop();
        }

        if (this.cameraState !== 0) {
            this.cb.onCameraOff();
        }

        window[this.id] = null;

        return null;
    }

    public setVolume(volume: number): void {
        this.eorq(() => this.swf.setVolume(volume));
    }

    private eorq(cmd: () => void, onFail?: () => void): void {
        if (this.state !== PlayerState.UNDEF) {
            cmd();
        } else {
            this.queue.push({ cmd, onFail });
        }
    }

    private playNext(): void {
        const source = this.sources.shift();
        if (source) {
            this.currentSource = source.stream;
            this.eorq(() => this.swf.play(source.app, source.stream));
        } else {
            this.cb.onError('no_source');
        }
    }

    private sendPlayInfo(): void {
        this.cb.onPlayInfo({
            width: this.videoWidth,
            height: this.videoHeight,

            quality: 'good',
            volume: this.swf.getVolume(),
            paused: false,
            name: this.name,
            source: this.currentSource
        });
    }
}
