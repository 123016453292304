import { IPlayer } from '../../iplayer';
import { SourceSet, H5LiveSource } from 'vchat-core';
import { Callbacks } from '../../callbacks';
import {
    NanoPlayerEvent,
    SourceEntry,
    NanoPlayerConfig,
} from './nano-player-types';
import './nanoplayer.4.3.2.min.js';

export class H5LivePlayer implements IPlayer {
    el: HTMLElement[];
    public readonly name = 'H5Live';
    public readonly canPublish? = false;

    private _player: NanoPlayer;
    private _callbacks: Callbacks;
    private _playerDiv: HTMLDivElement;
    private _currentSource?: H5LiveSource;
    private _volume?: number;
    private _isPlayerMuted: boolean;
    private _playerVolume: number;
    private _paused = false;

    private get playerVid(): HTMLVideoElement {
        return (
            this._playerDiv?.querySelector('video') ||
            this._playerDiv
                ?.querySelector('iframe')
                .contentDocument.querySelector('video')
        );
    }

    public constructor(callbacks: Callbacks, playerElement: HTMLDivElement) {
        this._callbacks = callbacks;
        this._playerDiv = playerElement;
        this.el = [playerElement];
    }

    public async play(sourceSet: SourceSet): Promise<void> {
        const h5liveSource = sourceSet.h5live[0];
        this._currentSource = h5liveSource;

        if (!this._player) {
            this._player = await this._createPlayer(h5liveSource);
        } else {
            const source = {
                entries: [this._createEntry(h5liveSource)],
            };

            await this._player.updateSource(source);
        }
    }

    stop(): void {
        this._player.pause();
    }

    destroy(): HTMLVideoElement {
        this._currentSource = null;
        this._player?.destroy();
        return null;
    }

    setVolume(volume: number): void {
        if (this._player) {
            this._player.setVolume(volume);

            if(volume > 0){
                this._player.unmute();
            } else {
                this._player.mute();
            }
        } else {
            // store this for when the player is started
            this._volume = volume;
        }
    }

    private _shouldPlayerStartMuted(): boolean {
        return this._volume === 0;
    }

    private _createEntry(h5liveSource: H5LiveSource): SourceEntry {
        const entry: SourceEntry = {
            h5live: {
                server: h5liveSource.server,
            },
        };

        if (h5liveSource.rtmp) {
            entry.h5live.rtmp = h5liveSource.rtmp;
        }

        if (h5liveSource.bintu) {
            entry.bintu = h5liveSource.bintu;
        }

        if (h5liveSource.token) {
            entry.h5live.token = h5liveSource.token;
        }

        return entry;
    }

    private _onPlay(_event: NanoPlayerEvent): void {
        this._paused = false;
        this._callbacks.onPlayStart(this._currentSource.rtmp.streamname);
        this.sendPlayInfo();
    }

    private sendPlayInfo(): void {
        const v = this.playerVid;

        if (this._player) {
            this._callbacks.onPlayInfo({
                width: v.clientWidth,
                height: v.clientHeight,
                quality: 'medium',
                paused: this._paused,
                volume: this._volume,
                name: this.name,
                source: this._currentSource?.rtmp.streamname,
            });
        }
    }

    private _onVolumeChange(event: NanoPlayerEvent): void {
        this._playerVolume = event.data.volume;

        this._updateCombinedVolume();

    }

    private _updateCombinedVolume(): void {
        const newCombinedVolume = this._isPlayerMuted ? 0 : this._playerVolume;

        if(this._volume !== newCombinedVolume){
            this._volume = newCombinedVolume;
            this._callbacks.onVolumeChange(this._volume);
            this.sendPlayInfo();
        }
    }

    private _onWarning(_event: NanoPlayerEvent): void {
        this._callbacks.onSendMetrics('h5live_warning', _event.data);
    }

    private _onError(event: NanoPlayerEvent): void {
        this._callbacks.onError(event.data);
    }

    private _onPause(_event: NanoPlayerEvent): void {
        this._paused = true;
        this._callbacks.onPlayStop();
        this.sendPlayInfo();
    }

    private _onStats(event: NanoPlayerEvent): void {
        // this._callbacks.onSendMetrics('h5live_stats', event.data.stats);
    }

    private _onMute(event: NanoPlayerEvent): void {
        this._isPlayerMuted = true;
        this._updateCombinedVolume();
    }

    private _onUnmute(event: NanoPlayerEvent): void {
        this._isPlayerMuted = false;
        this._updateCombinedVolume();
    }

    private async _createPlayer(
        h5liveSource: H5LiveSource
    ): Promise<NanoPlayer> {
        // eslint-disable-next-line no-undef
        const player = new NanoPlayer(this._playerDiv.id);

        const config: NanoPlayerConfig = {
            source: {
                entries: [this._createEntry(h5liveSource)],
            },
            events: {
                onPlay: this._onPlay.bind(this),
                onVolumeChange: this._onVolumeChange.bind(this),
                onWarning: this._onWarning.bind(this),
                onError: this._onError.bind(this),
                onPause: this._onPause.bind(this),
                onStats: this._onStats.bind(this),
                onMute: this._onMute.bind(this),
                onUnmute: this._onUnmute.bind(this),
            },
            playback: {
                autoplay: true,
                automute: true,
                muted: this._shouldPlayerStartMuted(),
                // videoId: this._playerVid.id
            },
            style: {
                width: 'auto',
                height: 'auto',
                controls: false,
                interactive: false,
                view: false,
            },
            metrics: {
                accountId: h5liveSource.metrics?.id, // do not change
                accountKey: h5liveSource.metrics?.key, // do not change
                userId: h5liveSource.metrics?.dataId, // value can be changed per viewer
                // eventId: 'event1',          // value can be changed per event
                statsInterval: 10, // statistics interval in seconds
            },
        };

        await player.setup(config);

        if(this._volume !== undefined)
        {
            player.setVolume(this._volume);
        }

        const v = this.playerVid;

        // this needs to be reset, as player.setup will delete the previous set style
        v.style.width = '100%';
        v.style.height = '100%';

        return player;
    }
}
