import { logger, PicStream, Source, SourceSet } from 'vchat-core';
import { Callbacks } from '../callbacks';
import { IPlayer } from '../iplayer';
import { PlayInfo } from '../play-info';
import { PlayerFactory } from '../player-factory';

export interface JpegPlayerConfig {
    /** called when the player has successfully been initialized; it retrieves a parameter hasAudio which indicates whether or not the player started with audio */
    initCallback?: (hasAudio: boolean) => void;
    /** when set to true the additional audio stream will not be loaded; it is advisable to deactivate audio at the moment due to some quality issues */
    noAudio?: boolean;
}

/**
 * A player for a JPEG stream (using the PicStream class from vchat-core). In addition an mp3 or OGG/Vorbis stream can be played to add sound to the images.
 */
export class JpegPlayerFactory implements PlayerFactory {
    public readonly format = 'jpeg';

    private config: JpegPlayerConfig;

    constructor(config?: JpegPlayerConfig) {
        this.config = config || {};
    }

    public isSupported(): boolean {
        return true;
    }

    public async create(callbacks: Callbacks): Promise<IPlayer> {
        const audioElement: HTMLAudioElement = document.createElement('audio');

        const supports: object = {
            'audio/mp3':
                audioElement.canPlayType &&
                audioElement
                    .canPlayType('audio/mpeg;codecs=mp3')
                    .replace(/no/, ''),
            'audio/ogg':
                audioElement.canPlayType &&
                audioElement.canPlayType('audio/ogg').replace(/no/, '')
        };

        const canAudio = supports['audio/mp3'] || supports['audio/ogg'];

        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.style.width = '100%';
        canvas.style.height = '100%';

        const withAudio: boolean = !this.config.noAudio && canAudio;
        let dataEventSend = false;
        let currentSource = '';
        let isStopped = true;

        let audio: HTMLAudioElement;
        if (withAudio) {
            audio = document.createElement('audio');
            audio.autoplay = true;

            canvas.append(audio);
        }

        const picStream = new PicStream((image: HTMLImageElement) => {
            if (!dataEventSend) {
                dataEventSend = true;

                const info: PlayInfo = {
                    width: image.naturalWidth,
                    height: image.naturalHeight,

                    quality: 'medium',
                    paused: false,
                    volume: withAudio ? audio.volume : 0,
                    name: player.name,
                    source: currentSource
                };
                callbacks.onPlayInfo(info);
            }

            const aspectRatio = image.width / image.height;

            if (canvas.width != canvas.clientWidth) {
                canvas.width = canvas.clientWidth;
            }

            if (canvas.height != canvas.clientHeight) {
                canvas.height = canvas.clientHeight;
            }

            let dw = 0;
            let dh = 0;

            if (aspectRatio * canvas.height > canvas.width) {
                dw = canvas.width;
                dh = canvas.width / aspectRatio;
            } else {
                dw = canvas.height * aspectRatio;
                dh = canvas.height;
            }

            const offsetX = (canvas.width - dw) / 2;
            const offsetY = (canvas.height - dh) / 2;

            const ctx = canvas.getContext('2d');

            ctx.drawImage(
                image,
                0,
                0,
                image.width,
                image.height,
                offsetX,
                offsetY,
                dw,
                dh
            );
        });

        const player = {
            el: [canvas],
            name: 'JPEG',

            play: (sourceSet: SourceSet): void => {
                player.stop();

                let source: string;
                let sourceType: string;
                let sources: Source[];

                isStopped = false;

                sources = sourceSet.jpeg;
                if (sources && sources.length > 0) {
                    currentSource = sources[0].stream;
                    picStream.play(currentSource);
                }

                if (withAudio) {
                    const d = [
                        sourceSet.mp3,
                        'audio/mp3',
                        sourceSet.vorbis,
                        'audio/ogg'
                    ];
                    for (let i = 0; i < 2; i++) {
                        sources = d[i * 2] as any;
                        sourceType = d[i * 2 + 1] as string;
                        if (
                            sources &&
                            sources.length > 0 &&
                            supports[sourceType]
                        ) {
                            source = sources[0].stream;
                            const src: HTMLSourceElement = document.createElement(
                                'source'
                            );
                            src.src = source;
                            src.type = d[i * 2 + 1] as string;
                            audio.append(src);
                        }
                    }

                    const r = audio.play() as any;
                    if (r && r.catch) {
                        r.catch(() => {
                            audio.volume = 0;
                            audio.muted = true;

                            callbacks.onVolumeChange(0);
                            logger.warn('JpegPlayerFactory audio.play failed');
                        });
                    }
                }
                callbacks.onPlayStart(source);
            },

            stop: (): void => {
                isStopped = true;

                picStream.stop();
                if (withAudio) {
                    audio.pause();
                    while (audio.firstChild) {
                        audio.removeChild(audio.firstChild);
                    }
                }
                dataEventSend = false;
                callbacks.onPlayStop();
            },

            destroy: (): HTMLVideoElement => {
                player.stop();

                return null;
            },

            setVolume: (value: number): void => {
                if (withAudio && !isStopped) {
                    audio.volume = value;
                    audio.muted = !value; // special handling for mobile Safari

                    if (audio.paused) {
                        const playPromise = audio.play();
                        if (playPromise) {
                            playPromise.catch(() => {
                                logger.warn(
                                    'JpegPlayerFactory audio.play failed'
                                );
                            });
                        }
                    }
                }
            }
        };

        if (this.config.initCallback) {
            this.config.initCallback(withAudio);
        }

        return player;
    }
}
